<template>
  <div class="tab_content_item">
    <div class="dynamic_new">
      <div class="f-r-b-c mb64 dynamic_new_box">
        <ul class="dynamic_list">
          <li
            v-for="(item, index) in typeList"
            :key="index"
            :class="curTab == index ? 'act' : ''"
            @click="changeTab(index, item)"
          >
            {{ item.digest }}
          </li>
        </ul>
        <div class="channel_app_show">
          <select @change="changeMobileTab" v-model="digest">
            <option
              :value="item.digest"
              v-for="(item, index) in typeList"
              :key="index"
            >
              {{ item.digest }}
            </option>
          </select>
        </div>
<!--        <span class="length_ft">{{ totalPage }}个内容</span>-->
      </div>
      <div
        class="dynamic_html_box"
        v-for="(item, index) in banbenList"
        :key="index"
      >
        <i
          class="dynamic_html_box_img"
          :style="{
            backgroundImage: 'url(' + item.imgurl + ')',
          }"
        ></i>
        <div class="f-r-b-c mt24 length_ft_box">
          <span class="length_ft">{{ item.digest }}</span>
          <span class="length_ft">{{ item.created_at }}</span>
        </div>
        <p class="dynamic_html_desc" @click="routerLink(item)">
          {{ item.title }}
        </p>
      </div>
      <p class="dynamic_more" @click="handleMore" v-if="total_pages !== page">查看更多</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      typeList: [],
      curTab: 0,
      page: 1,
      banbenList: [],
      totalPage: 0,
      digest: "",
      total_pages:0
    };
  },
  methods: {
    getTypeList() {
      this.$axios.get("v1/material/get_type_list",{headers:{ua:"pms"}}).then((res) => {
        console.log(res);
        this.typeList = res.data.data;
        this.typeList.unshift({digest: "所有动态"});
        this.digest = this.typeList[0].digest;
        this._fetchList(this.digest).then((res) => {
          this.totalPage = res.data.meta.pagination.total;
          this.page = res.data.meta.pagination.current_page;
          this.banbenList = res.data.data;
          this.total_pages  = res.data.meta.pagination.total_pages;
        });
      });
    },
    changeTab(index, item) {
      this.curTab = index;
      this.page = 1;
      this.digest = item.digest;
      this._fetchList(this.digest).then((res) => {
        this.totalPage = res.data.meta.pagination.total;
        this.page = res.data.meta.pagination.current_page;
        this.banbenList = res.data.data;
      });
    },
    _fetchList(val) {
      console.log(val);
      if (val === "所有动态") {
        val = "";
      }
      return this.$axios
        .get("/v1/material/get_list", {
          params: {
            page: this.page,
            digest: val,
          },
          headers:{
            ua:"pms"
          }
        })
        .then((res) => {
          console.log(res);
          return Promise.resolve(res);
        });
    },
    routerLink(item) {
      window.open(item.url, "_blank");
    },
    handleMore() {
      this.page++;
      this._fetchList(this.digest).then((res) => {
        console.log(res);
        this.banbenList = [...this.banbenList, ...res.data.data];
      });
    },
    changeMobileTab() {
      this.page = 1;
      this._fetchList(this.digest).then((res) => {
        this.totalPage = res.data.meta.pagination.total;
        this.page = res.data.meta.pagination.current_page;
        this.banbenList = res.data.data;
      });
    },
  },
  computed: {},
  props: {},
  watch: {},
  created() {},
  mounted() {
    this.getTypeList();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>
