<template>
  <div class="tab_content_item">
    <div class="concat_us">
      <div class="corporate-culture">
        <p class="culture-title" style="text-align: center">企业文化</p>
        <p class="cultrue-desc mt40">
          “简单、务实、好学、求变”，是我们的企业文化。我们希望你，简单做事、工作务实、持续学习、拥抱变化。我们更希望你，能够适应行业的更迭，和公司一起成长，成为更好的自己，成为能够独当一面的优秀人才。
        </p>
        <p class="cultrue-desc">
          作为一家互联网公司，我们欢迎所有年龄段的人，不管你是00后，还是70后，毕竟领导着我们这群90后的就是一位70后。只要你有能力，我们期待与你一起共事！
        </p>
        <p class="cultrue-desc">
          “没有终局的成功，也没有致命的失败，重要的是继续前进的勇气”。
        </p>
        <p class="cultrue-desc">如果你对我们感兴趣，那请加入我们吧！</p>
      </div>
      <div class="corporate-culture">
        <p class="culture-title">公司福利</p>
        <ul class="company-benefits">
          <li>
            <i class="icon_dot"></i>
            <span class="benefits-item">五险一金</span>
          </li>
          <li>
            <i class="icon_dot"></i>
            <span class="benefits-item">周末双休</span>
          </li>
          <li>
            <i class="icon_dot"></i>
            <span class="benefits-item">带薪年假</span>
          </li>
          <li>
            <i class="icon_dot"></i>
            <span class="benefits-item">晋升加薪</span>
          </li>
          <li>
            <i class="icon_dot"></i>
            <span class="benefits-item">每日阅读</span>
          </li>
          <li>
            <i class="icon_dot"></i>
            <span class="benefits-item">定期团建</span>
          </li>
          <li>
            <i class="icon_dot"></i>
            <span class="benefits-item">节庆关怀</span>
          </li>
          <li>
            <i class="icon_dot"></i>
            <span class="benefits-item">年度体检</span>
          </li>
        </ul>
        <p class="tc bottom-text">- 这些都是人之常情 -</p>
        <p class="mt24 benefits-text">
          真正的福利是：可以让你自由的工作！
          <br />
          反对一切“内卷文化”、“狗腿文化”、“下班演加班”等。
          <br />
          咳咳~大家都是职场人，话说的够明白了吧~
          <br />
          尊老爱幼，不搞假大空，做好实在事！
        </p>
      </div>
      <div class="corporate-culture">
        <p class="culture-title">招聘岗位</p>
        <div class="post-list">
          <div class="post-item" :class="postList.xiaoshou? 'post-details-show' : ''">
            <div class="post-title-box" @click="handleShow('xiaoshou')">
              <p class="title">客户经理/电话销售（接受实习生）</p>
              <div class="mr24 f-r-s-c">
                <p class="post-city">上海</p>
                <i class="selected-arrow-right"></i>
              </div>
            </div>
            <div v-if="postList.xiaoshou">
              <div class="post-details">
                <p class="desc">岗位职责：</p>
                <p class="desc">
                  1、负责管辖区域内云掌柜PMS（住宿业数字化营销管家）及增值运营业务的推广与拓新；
                </p>
                <p class="desc">
                  2、了解区域内民宿经营情况，通过电联和当面拜访深挖客户需求，能够结合产品和业务为客户提供解决方案；
                </p>
                <p class="desc">
                  3、负责已成交客户的维护与回访，协助售后部门增加客户粘性，提高客户满意度。
                </p>
              </div>
              <div class="post-details mt24">
                <p class="desc">岗位要求：</p>
                <p class="desc">
                  1、大专及以上学历，1年以上销售工作经验，有SaaS软件/旅游行业的电销/面销经验者优先；
                </p>
                <p class="desc">
                  2、具有良好的人际沟通、谈判能力、分析和解决问题的能力，较强的亲和力；
                </p>
                <p class="desc">
                  3、具有超强的执行力，强烈的责任感和学习欲望，渴望挑战自己；
                </p>
                <p class="desc">
                  4、欢迎优秀应届毕业生，在校期间有过销售类/民宿类/互联网旅游类实习经历，或者学生会/社团任职经历者优先考虑。（接受实习生）
                </p>
              </div>
              <div class="post-details">
                <p class="desc">简历投递：chunxiao.yang@ly.com</p>
                <p class="desc">联系电话：021-50330653</p>
              </div>
            </div>
          </div>
          <div class="post-item"  :class="postList.kefu? 'post-details-show' : ''">
            <div class="post-title-box" @click="handleShow('kefu')">
              <p class="title">客服专员</p>
              <div class="mr24 f-r-s-c">
                <p class="post-city">上海</p>
                <i class="selected-arrow-right"></i>
              </div>
            </div>
            <div v-if="postList.kefu">
              <div class="post-details">
                <p class="desc">岗位职责：</p>
                <p class="desc">
                  1、通过微信、电话、QQ等方式及时解决用户在使用产品过程中遇到的问题，并做好客情维护与安抚，保证客户满意度；
                </p>
                <p class="desc">
                  2、为新成交用户开通微信公众号及微信小程序，引导用户正确使用产品；
                </p>
                <p class="desc">
                  3、主动积极地站在客户需求的视角，结合场景给客户提出的问题做分析定位，协助技术团队为客户提供解决方案；
                </p>
                <p class="desc">
                  5、主动总结分析和反馈服务中各种产品问题，向产品团队提出改善意见与需求，从客户角度推动产品迭代优化；
                </p>
                <p class="desc">
                  4、通过运营行为分析，主动发现售后服务的异常问题，找出问题并提出优化建议，并做好归档和服务交接工作表；
                </p>
                <p class="desc">6、完成上级交办的其他任务。</p>
              </div>
              <div class="post-details mt24">
                <p class="desc">岗位要求：</p>
                <p class="desc">1、大专以上学历，22-28岁，男女不限；</p>
                <p class="desc">
                  2、至少2年服务或者销售工作经验，有SaaS、计算机、软件等行业工作经验优先；
                </p>
                <p class="desc">
                  3、优秀的主动服务意识和责任心，良好的沟通能力和学习能力，工作细心踏实；
                </p>
                <p class="desc">
                  4、可接受早晚班，早班9:00-18:00，晚班12:00-21:00；
                </p>
                <p class="desc">
                  5、较快的打字速度，每分钟不低于80字，熟练使用计算机及办公软件。
                </p>
              </div>
              <div class="post-details">
                <p class="desc">简历投递：chunxiao.yang@ly.com</p>
                <p class="desc">联系电话：021-50330653</p>
              </div>
            </div>
          </div>
          <div class="post-item"  :class="postList.chanpin? 'post-details-show' : ''">
            <div class="post-title-box" @click="handleShow('chanpin')">
              <p class="title">产品经理</p>
              <div class="mr24 f-r-s-c">
                <p class="post-city">上海</p>
                <i class="selected-arrow-right"></i>
              </div>
            </div>
            <div v-if="postList.chanpin">
              <div class="post-details">
                <p class="desc">岗位职责：</p>
                <p class="desc">
                  1、负责云掌柜PMS的前后端产品的整体迭代及维护；
                </p>
                <p class="desc">2、协调产研测试资源，对需求落地负责；</p>
                <p class="desc">
                  3、负责调研挖掘并分析业务需求、根据实际业务设计合理的产品方案；
                </p>
                <p class="desc">
                  4、把控产品开发进度，负责跨部门沟通协作，推动上线后的产品内外部培训及产品教育；
                </p>
                <p class="desc">
                  5、监控产品运营数据、分析并持续改善产品，提升B端用户体验。
                </p>
              </div>
              <div class="post-details mt24">
                <p class="desc">岗位要求：</p>
                <p class="desc">
                  1、全日制本科学历及以上、2年PMS、SaaS、B端相关工作经验（无经验请勿扰）；
                </p>
                <p class="desc">
                  2、有OTA、民宿、公寓等平台相关产品设计经验优先；
                </p>
                <p class="desc">
                  3、熟悉民宿类产品或对旅游行业有一定了解，可加分；
                </p>
                <p class="desc">
                  4、需求落地能力强、有脑洞、逻辑清晰、风险把控力高；
                </p>
                <p class="desc">
                  5、擅长做减法、能识别产品最小核心并有验证方法、快速验证试错能力；
                </p>
                <p class="desc">
                  6、产品基础技能扎实，原型、PRD、流程图、项目把控能力过硬。
                </p>
              </div>
              <div class="post-details">
                <p class="desc">简历投递：chunxiao.yang@ly.com</p>
                <p class="desc">联系电话：021-50330653</p>
              </div>
            </div>
          </div>
          <div class="post-item" :class="postList.qianduan? 'post-details-show' : ''">
            <div class="post-title-box" @click="handleShow('qianduan')">
              <p class="title">前端开发工程师</p>
              <div class="mr24 f-r-s-c">
                <p class="post-city">上海</p>
                <i class="selected-arrow-right"></i>
              </div>
            </div>
            <div v-if="postList.qianduan">
              <div class="post-details">
                <p class="desc">岗位职责：</p>
                <p class="desc">1、负责公司核心业务前端业务开发；</p>
                <p class="desc">2、持续优化前端开发模式和规范；</p>
                <p class="desc">
                  3、配合后端开发人员完成前端应用开发和后端系统的对接；
                </p>
                <p class="desc">
                  4、服从项目的目标和任务安排，配合团队完成开发任务指标；
                </p>
                <p class="desc">5、持续优化前端开发模式和规范。</p>
              </div>
              <div class="post-details mt24">
                <p class="desc">岗位要求：</p>
                <p class="desc">
                  1、本科及以上学历，计算机相关专业毕业，具有1年以上实实在在的工作经验，有上线作品并进行演示；
                </p>
                <p class="desc">
                  2、使用Vue至少1年经验，会用，最好知道为什么这么用；
                </p>
                <p class="desc">
                  3、对前端工程化与模块化开发有一定了解，能制作封装独立插件并解决兼容问题；
                </p>
                <p class="desc">
                  4、良好的沟通能力、抗压能力，做事积极主动，善于沟通；
                </p>
                <p class="desc">
                  5、有较好的产品意识优先，责任心强，工作认真细致，具有良好的团队沟通与协作能力；
                </p>
                <p class="desc">
                  6、热爱编程，有较强的学习能力，有强烈的求知欲、好奇心和进取心，能及时关注和学习业界最新技术。
                </p>
              </div>
              <div class="post-details">
                <p class="desc">简历投递：chunxiao.yang@ly.com</p>
                <p class="desc">联系电话：021-50330653</p>
              </div>
            </div>
          </div>
          <div class="post-item" :class="postList.houduan? 'post-details-show' : ''">
            <div class="post-title-box" @click="handleShow('houduan')">
              <p class="title">后端开发工程师</p>
              <div class="mr24 f-r-s-c">
                <p class="post-city">上海</p>
                <i class="selected-arrow-right"></i>
              </div>
            </div>
            <div v-if="postList.houduan">
              <div class="post-details">
                <p class="desc">岗位职责：</p>
                <p class="desc">1、负责服务端业务开发、架构设计等工作；</p>
                <p class="desc">
                  2、分析系统瓶颈，解决各种疑难问题，对系统进行性能调优；
                </p>
                <p class="desc">3、负责API接口的编写</p>
                <p class="desc">4、对系统进行定期优化：SQL和代码调优。</p>
              </div>
              <div class="post-details mt24">
                <p class="desc">岗位要求：</p>
                <p class="desc">1、本科及以上学历，计算机、通信等相关专业；</p>
                <p class="desc">
                  2、有扎实的编程能力，追求优雅的设计和优秀的代码质量，高标准；
                </p>
                <p class="desc">
                  3、熟悉LNMP Web架构，熟悉laravel(PHP)者优先，3年以上实打实的经验；
                </p>
                <p class="desc">
                  4、熟悉Mysql、Memcache、Redis、消息队列等常用WEB组件；
                </p>
                <p class="desc">
                  5、有较好的产品意识优先，责任心强，工作认真细致，具有良好的团队沟通与协作能力；
                </p>
                <p class="desc">
                  6、热爱编程，有较强的学习能力，有强烈的求知欲、好奇心和进取心，能及时关注和学习业界最新技术。
                </p>
              </div>
              <div class="post-details">
                <p class="desc">简历投递：chunxiao.yang@ly.com</p>
                <p class="desc">联系电话：021-50330653</p>
              </div>
            </div>
          </div>
          <div class="post-item" :class="postList.ceshi? 'post-details-show' : ''">
            <div class="post-title-box" @click="handleShow('ceshi')">
              <p class="title">测试开发工程师</p>
              <div class="mr24 f-r-s-c">
                <p class="post-city">上海</p>
                <i class="selected-arrow-right"></i>
              </div>
            </div>
            <div v-if="postList.ceshi">
              <div class="post-details">
                <p class="desc">岗位职责：</p>
                <p class="desc">
                  1、参与系统测试及全流程，包括参与需求分析、制定测试计划，设计测试策略，设计和执行测试用例，进行缺陷跟踪定位；
                </p>
                <p class="desc">
                  2、独立完成各项测试工作：包括功能测试、接口测试、APP测试；
                </p>
                <p class="desc">
                  3、维护测试流程，分析测试结果，提高测试效率和质量；
                </p>
                <p class="desc">
                  4、编写用于测试的自动测试脚本，完整地记录测试结果；
                </p>
                <p class="desc">
                  5、与产品、研发、用户进行需求沟通反馈，保证产品质量。
                </p>
              </div>
              <div class="post-details mt24">
                <p class="desc">岗位要求：</p>
                <p class="desc">1、计算机及软件工程、通信等相关专业；</p>
                <p class="desc">
                  2、具备一年以上互联网软件测试经验，SaaS类从业者优先考虑；
                </p>
                <p class="desc">3、熟悉Linux操作系统，熟悉MySQL数据库；</p>
                <p class="desc">4、熟悉至少一种项目管理工具，如禅道，JIRA；</p>
                <p class="desc">
                  5、有测试经验，撰写过相关测试文档，进行过压力测试、兼容性测试优先考虑；
                </p>
                <p class="desc">
                  6、具有强烈的工作责任心、细心、有良好的沟通能力。
                </p>
              </div>
              <div class="post-details">
                <p class="desc">简历投递：chunxiao.yang@ly.com</p>
                <p class="desc">联系电话：021-50330653</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <i class="icon_slash"></i>
        <p class="yzg_envelope">期待您的来信</p>
      </div>
      <div class="mt80">
        <div class="f-r-s-s">
          <i class="icon_mailbox"></i>
          <div class="yzg_business_cooperation">
            <p>lzkj-marketing@ly.com​ (业务合作)</p>
          </div>
        </div>
      </div>
      <div class="mt48">
        <div class="f-r-s-s">
          <i class="yzg_phone"></i>
          <div class="yzg_business_cooperation">
            <p style="margin-bottom: 0;">0512-83621081 （服务时间：9:00-21:00）​</p>
          </div>
        </div>
      </div>
      <div>
        <img src="../assets/pic/map_of_china.png" alt="" class="map_of_china" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      postList: {
        xiaoshou: false,
        kefu: false,
        chanpin: false,
        qianduan: false,
        houduan: false,
        ceshi: false,
      },
    };
  },
  methods: {
    handleShow(type) {
      console.log(type, "type");
      this.postList[type] = !this.postList[type];
    },
  },
  computed: {},
  props: {},
  watch: {},
  created() {},
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
import
//@import url(); 引入公共css类
.corporate-culture p {
  margin-bottom: 0;
}
.tab_content_item .concat_us .mt80 {
  margin-top: 40px;
}

</style>
