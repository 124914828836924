<template>
  <div class="tab_content_item">
<!--    <div class="yzg_video yzg_video_media">-->
<!--&lt;!&ndash;      <video&ndash;&gt;-->
<!--&lt;!&ndash;        src="../assets/pic/yzgVideo.mp4"&ndash;&gt;-->
<!--&lt;!&ndash;        width="100%"&ndash;&gt;-->
<!--&lt;!&ndash;        height="100%"&ndash;&gt;-->
<!--&lt;!&ndash;        preload&ndash;&gt;-->
<!--&lt;!&ndash;        controls&ndash;&gt;-->
<!--&lt;!&ndash;        poster="../assets/pic/yzgVideoBackground.png"&ndash;&gt;-->
<!--&lt;!&ndash;      ></video>&ndash;&gt;-->
<!--      <iframe  height="414"  width="736" src='https://player.youku.com/embed/XNTE0NDQ3ODUzNg==' frameborder=0 allowfullscreen></iframe>-->
<!--    </div>-->
<!--    <div class="yzg_video yzg_video_h5_show">-->
<!--&lt;!&ndash;      <video&ndash;&gt;-->
<!--&lt;!&ndash;        src="../assets/pic/yzgVideo.mp4"&ndash;&gt;-->
<!--&lt;!&ndash;        width="100%"&ndash;&gt;-->
<!--&lt;!&ndash;        height="100%"&ndash;&gt;-->
<!--&lt;!&ndash;        preload&ndash;&gt;-->
<!--&lt;!&ndash;        controls&ndash;&gt;-->
<!--&lt;!&ndash;        poster="../assets/pic/yzgVideoBackground.png"&ndash;&gt;-->
<!--&lt;!&ndash;      ></video>&ndash;&gt;-->
<!--      <iframe  height="414"  width="736" src='https://player.youku.com/embed/XNTE0NDQ3ODUzNg==' frameborder=0 allowfullscreen></iframe>-->
<!--    </div>-->
    <div class="about_us_item">
      <p class="title">公司介绍</p>
      <p class="about_us_item_desc">
        北京米天下科技股份有限公司成立于2012年2月9日，创始人为前OTA资深从业者，互联网旅游基因浓厚。目前总部设立在上海，是一家致力于提高民宿运营和管理效率的一站式服务商。
      </p>
      <p class="about_us_item_desc">
        北京米天下科技股份有限公司推出的云掌柜民宿多终端房态管理系统（简称“云掌柜”）是目前公司的核心产品，拥有强大的管理和营销功能。
      </p>
      <p class="about_us_item_desc">
        云掌柜作为住宿业数字化营销管家，致力于通过专业化管理工具，帮助民宿提高运营水平，整合营销资源，构建生态系统，为民宿赋能，全方位提高综合收入。
      </p>
      <p class="about_us_item_desc">
        目前，云掌柜用户数已达{{userNumber}}万家，覆盖了丽江、大理、厦门、杭州、苏州、上海、北京、台湾、清迈、曼谷、东京等500多个海内外热门旅游目的地。​
      </p>
    </div>
    <div class="about_us_item">
      <p class="title">发展历程</p>
      <p class="about_us_item_desc">2012年初，创始人团队获天使轮融资</p>
      <p class="about_us_item_desc">2012年2月9日，北京米天下科技股份有限公司成立</p>
      <p class="about_us_item_desc">2013年8月5日，云掌柜PMS上线</p>
      <p class="about_us_item_desc">
        2013年9月，举办丽江发布会
      </p>
      <p class="about_us_item_desc">
        2014年初，获A轮融资
      </p>
      <p class="about_us_item_desc">2014年7月，云掌柜用户数突破1万</p>
      <p class="about_us_item_desc">2015年初，获B轮融资</p>
      <p class="about_us_item_desc">2016年3月，代销业务上线</p>
      <p class="about_us_item_desc">
        2016年8月，内部裂变式创业，芝麻云锁、千宿创立
      </p>
      <p class="about_us_item_desc">2016年12月，云优选业务上线</p>
      <p class="about_us_item_desc">
        2017年7月，推出增值付费业务，直连、VIP、小程序、民宿金融等
      </p>
      <p class="about_us_item_desc">2018年1月，OTA网站优化服务上线</p>
      <p class="about_us_item_desc">2018年6月，云掌柜用户数突破10万</p>
      <p class="about_us_item_desc">2018年8月，预售营销服务上线</p>
      <p class="about_us_item_desc">2019年7月，APP大改版，全面优化视觉体验</p>
      <p class="about_us_item_desc">
        2019年11月，连续三年获携程紧密合作伙伴称号
      </p>
      <p class="about_us_item_desc">2019年12月，抖音直连上线</p>
      <p class="about_us_item_desc">2020年6月，云掌柜用户数突破15万​</p>
      <p class="about_us_item_desc">2020年11月，云掌柜用户数突破16万​</p>
      <p class="about_us_item_desc">2020年12月，云掌柜与大连理工大学签署校企合作</p>
      <p class="about_us_item_desc">2020年12月，云掌柜品牌全面升级</p>
      <p class="about_us_item_desc">2021年2月，云掌柜集群版PMS正式上线​</p>
      <p class="about_us_item_desc">2021年4月，云掌柜与抖音签约民宿区域独家合作伙伴​</p>
      <p class="about_us_item_desc">2021年5月，云掌柜用户数突破17万</p>
    </div>
    <div class="about_us_item">
      <p class="title">品牌故事</p>
      <p class="about_us_item_desc">
        云掌柜深耕民宿行业{{createYear}}年，一直致力于通过专业化管理工具，帮助民宿提高运营水平，整合营销资源，构建生态系统。为民宿赋能，全方位提升综合收益。
      </p>
      <p class="about_us_item_desc">
        中国的民宿行业，从最早的市场培育野蛮生长、到行业的有序发展洗牌竞争，再到如今的创新破局生态竞争阶段。云掌柜陪伴着数以万计的民宿走过了整段历程，历经波澜，稳步前进。在大数据时代的背景下不断更迭产品与服务，来适配不同时代的民宿需求。
      </p>
      <p class="about_us_item_desc">
        截至目前，云掌柜的用户已遍布天下，北至漠河，南到三亚，{{userNumber}}万的海内外用户是云掌柜在这个行业里最好的品牌背书。
      </p>
      <p class="about_us_item_desc">
        莫听穿林打叶声，何妨吟啸且徐行。未来云掌柜也将继续陪伴各位掌柜们携手共进，砥砺前行。
      </p>
    </div>
<!--    <div class="about_us_item">-->
<!--      <p class="title">品牌IP</p>-->
<!--      <img src="../assets/pic/yzgFeartchImg.png" alt="" class="yzg_IP" />-->
<!--      <p class="yzgAboutImg_text" style="margin-bottom: 0;">-->
<!--        云掌柜推出全新IP形象“云小柜”。它既是「民宿管理好帮手」，也是「首席功能讲解官」，将负责为掌柜们讲解系统功能，解决使用过程中的各种问题。-->
<!--      </p>-->
<!--      <div class="f-r-c-c">-->
<!--        <img src="../assets/pic/yzgAboutImg.png" alt="" class="yzg_about_Ip" />-->
<!--      </div>-->
<!--      <p class="about_yzg_text">关于“云小柜”</p>-->
<!--      <ul class="about_yzg_list_ul">-->
<!--        <li>姓名：云小柜</li>-->
<!--        <li>生日：2020.12.25</li>-->
<!--        <li>星座：摩羯座</li>-->
<!--        <li>喜好：喜欢研究系统功能，爱看报表，但不喜欢被别人摸刘海儿</li>-->
<!--        <li>性格：看着呆萌却很专业，乐于助人，非常热情</li>-->
<!--        <li>身份：民宿管理好帮手&首席功能讲解官</li>-->
<!--        <li>口头禅：让我来帮助你吧！</li>-->
<!--      </ul>-->
<!--      <div class="f-c-c-c">-->
<!--        <img src="../assets/pic/yzgCeoImg.png" alt="" class="ceo-ip" />-->
<!--        <p class="ceo-ip-text">CEO：撸起袖子加油干！</p>-->
<!--      </div>-->
<!--      <div class="ip-fetarch">-->
<!--        <div class="item">-->
<!--          <img src="../assets/pic/yzgServerImg.png" alt="" />-->
<!--          <p class="info-text">客服：有最暖的微笑，和最专业的态度</p>-->
<!--        </div>-->
<!--        <div class="item">-->
<!--          <img src="../assets/pic/yzgShichang.png" alt="" />-->
<!--          <p class="info-text">市场：让云掌柜C位出道！</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="ip-fetarch">-->
<!--        <div class="item">-->
<!--          <img src="../assets/pic/yzgMarket.png" alt="" />-->
<!--          <p class="info-text">销售：掌柜您好，云掌柜了解一下？</p>-->
<!--        </div>-->
<!--        <div class="item">-->
<!--          <img src="../assets/pic/yzgProduct.png" alt="" />-->
<!--          <p class="info-text">产品：想要什么功能，跟我说！</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="ip-fetarch">-->
<!--        <div class="item">-->
<!--          <img src="../assets/pic/yzgYanfa.png" alt="" />-->
<!--          <p class="info-text">研发：新功能上线了，耶！头发又掉了，呜~</p>-->
<!--        </div>-->
<!--        <div class="item">-->
<!--          <img src="../assets/pic/yzgZengzhi.png" alt="" />-->
<!--          <p class="info-text">增值：来呀，一起搞钱~</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      createYear: new Date().getFullYear() - 2012,
    };
  },
  methods: {},
  computed: {},
  props: ["userNumber"],
  watch: {},
  created() {},
  mounted() {
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>
