<template>
  <div class="">
    <YzgHeader></YzgHeader>
    <div class="about_us_wrapper">
      <div class="about_us_header_bg relative">
        <div class="about_us_bg_img">
          <div class="about_us_header_positions_box">
            <p style="margin-bottom: 0;">云掌柜</p>
            <p  style="margin-bottom: 0;">
              专注于服务好每一位掌柜，持续给用户提供长期、稳定的服务，让天下没有难做的民宿生意。​
            </p>
            <p>专业 · 靠谱 · 创新​</p>
          </div>
        </div>
      </div>
      <div class="about_us_tab">
        <ul class="tab_list">
          <li
            v-for="(text, index) in tabsList"
            :key="index"
            :class="index == tabCurrent ? 'act' : ''"
            @click="tabCurrent = index">
            {{ text }}
          </li>
        </ul>
      </div>
      <div class="about_us_tab_content">
        <keep-alive>

          <AboutUsTab v-if="tabCurrent == 0" :userNumber="userNumber"></AboutUsTab>
        </keep-alive>
        <NewDynamic v-if="tabCurrent == 1"></NewDynamic>
        <keep-alive>
          <CooperativePartner v-if="tabCurrent == 2"></CooperativePartner>
        </keep-alive>
        <keep-alive>
          <JoinUs v-if="tabCurrent == 3"></JoinUs>
        </keep-alive>
      </div>
    </div>
    <YzgFooter></YzgFooter>
  </div>
</template>

<script>
import YzgHeader from "@/components/YzgHeader";
import YzgFooter from "@/components/YzgFooter";
import AboutUsTab from "@/components/AboutUsTab";
import NewDynamic from "@/components/NewDynamic";
import CooperativePartner from "@/components/CooperativePartner";
import JoinUs from "@/components/JoinUs";
import axios from "axios";
export default {
  name: "aboutUs",
  components: {
    YzgFooter,
    YzgHeader,
    AboutUsTab,
    NewDynamic,
    CooperativePartner,
    JoinUs,
  },
  data() {
    return {
      tabsList: ["关于云掌柜", "最新动态", "合作伙伴", "加入我们"],
      tabCurrent: 0,
      userNumber: ""
    };
  },
  methods: {
    getUserNumber() {
      axios.get('https://api.yunzhanggui.net/api/v1/inn/get_hotel_total?version=9.4.6.0')
          .then((res) => {
            let number = Number(res.data.ret_data.total) / 10000
            this.userNumber = Math.floor(number)
            // this.userNumber = Number(res.data.ret_data.total) / 10000
            console.log(res.data.ret_data.total)
          })
          .catch(function (err) {
            return err
          })
    }
  },
  created() {
    this.getUserNumber()
  },
  computed: {},
  props: {},
  watch: {},
  mounted() {
    let queryId = this.$route.query.id;
    this.tabCurrent = queryId;
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
@import url("../assets/index.css");
@import url("../assets/media.css");
</style>
