<template>
  <div class="tab_content_item">
    <div class="ota_channel_box">
      <div class="ota-logo-box">
        <p class="ota-title">直连渠道</p>
        <img src="../assets/pic/pc-about-media.png" alt="" class="ota-phota" />
      </div>
      <div class="ota-logo-box">
        <p class="ota-title">代销渠道</p>
        <img src="../assets/pic/pc-about-dx.png" alt="" class="ota-phota" />
      </div>
      <div class="ota-logo-box">
        <p class="ota-title">媒体渠道</p>
        <img
          src="../assets/pic/pc-aboutus-channel.png"
          alt=""
          class="ota-phota"
        />
      </div>
    </div>
    <div class="app_ota_channel_box">
      <ul class="tab">
        <li 
          :class="mobileCurrentImg==index?'act':''" 
          v-for="(item,index) in moblieList" :key="index"
          @click="mobileCurrentImg = index"
        >
          {{item}}
        </li>
       
      </ul>
      <div class="channel_img">
        <img
          v-if="mobileCurrentImg==0"
          src="../assets/pic/h5-channel.png" alt="" />
        <img 
          v-if="mobileCurrentImg==1"
          src="../assets/pic/h5-dx.png" alt="" />
        <img 
          v-if="mobileCurrentImg==2"
          src="../assets/pic/h5-media.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      mobileCurrentImg:0,
      moblieList:[
        '直连渠道','代销渠道','媒体渠道'
      ]
    };
  },
  methods: {},
  computed: {},
  props: {},
  watch: {},
  created() {},
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>